.mainbutton {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%);
}

.main_button_ancre {
  width: 40px;
  height: 40px;
  z-index: 99;
}

.circle {
  z-index: 0;
  height: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

#circle {
  stroke-miterlimit: 1;
  stroke-dasharray: 50;
  z-index: 99;
  transform: rotate(90deg);
}

.second_cercle {
  z-index: 0;
}

.anim_cercle {
  stroke-dasharray: 50;
  stroke-width: .3px;
  stroke: #ffe0dc;
  stroke-dashoffset: 50px;
  animation: .325s emptyOutline;
}

@keyframes emptyOutline {
  from {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -50px;
  }
}

.anim_cercle:hover {
  stroke-dashoffset: 0;
  animation: .325s fillOutline;
}

@keyframes fillOutline {
  from {
    stroke-dashoffset: 50px;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.arrow {
  fill: none;
  stroke-width: 1.5px;
  stroke: #ffe0dc;
  stroke-miterlimit: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  z-index: 0;
  position: absolute;
  top: 5px;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%);
}

.arrow2 {
  fill: none;
  stroke-width: 1.5px;
  stroke: #ffe0dc;
  stroke-miterlimit: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  z-index: -1;
  position: absolute;
  top: 100px;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%);
}

.anim_arrow {
  transform: matrix(0, 0, 0, 0);
  animation: .325s emptyTranslate;
}

@keyframes emptyTranslate {
  from {
    transform: matrix(0, 0, 0, 0);
  }

  to {
    transform: matrix(0, 0, 0, 2);
  }
}

/*# sourceMappingURL=index.5e76a0cb.css.map */
